import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import CustomTextField from "../../../Components/CustomTextfield";
import {
  Input,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ObjectValues, inputProp } from "../../login";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { headerContent, subHeading } from "../../../Components/CustomTexts";
import MultipleAutoComplte from "../../../Components/MultipleAutoComplte";
import CustomCkeditor from "../../../Components/CustomCkeditor";
import globalColors from "../../../globalColors";
import {
  displayColumn,
  displayRow,
  rowCenter,
  rowSB,
} from "../../../GlobalStyles";
import CustomIconButton from "../../../Components/CustomIconButton";
import CustomContainedButton from "../../../Components/CustomContainedButton";
import { useAppDispatch, useAppSelecter } from "../../../hooks/storeHooks";
import { showSnack } from "../../../store/slices/constants";
import CustomDatePicker from "../../../Components/CustomDatePicker";
import moment from "moment";
import { StopScroll } from "../../../store/slices/constants/staticContents";
import CopyComponent from "../../../Components/CopyComponent";
import { Addicon, copyIcon, cross, rightIcons } from "../../../assets/svgs";
import SvgPreview from "../../../Components/SvgPreview";
import { formCompProps } from "../../../Components/FormComponent";
import apiRequest from "../../../request/apiRequest/apiRequest";
import CustomAutocomplete from "../../../Components/CustomAutocomplete";
import { fetchSinlgQustion } from "../../../store/slices/exams/thunks/fetchContentData";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { allApis } from "../../../request/config";
import PreviewComponent from "../../mockTest/QuestionFormComponentMock/previewComponent";
import axios from "axios";
import mammoth from "mammoth";

interface quesformProp extends formCompProps {
  getApi: any;
  fromCluster?: true;
}
function QuestionFormComponentExams(props: quesformProp) {
  const {
    onClear,
    onSubmit,
    api,
    editData,
    header,
    inputFormArray,
    extraFields,
    formatPayload,
    successMessage,
    oldSubmittedData,
    inputSteps,
    customContent,
    getApi,
    fromCluster,
  } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [fileUrl, setFileUrl] = useState<string>(""); // To store user input URL
  const [jsonData, setJsonData] = useState<object | null>(null);
  const [error, setError] = useState<string | null>(null); // To store error messages

  const handleExtractData = async () => {
    if (!fileUrl) {
      setError("Please enter a valid file URL.");
      return;
    }

    try {
      setError(null); // Clear previous errors

      const exportUrl = fileUrl.replace(/\/edit.*$/, "/export?format=docx");

      // Fetch the Word file as an array buffer
      const response = await axios.get(exportUrl, { responseType: "arraybuffer" });

      // Extract text from the Word file using mammoth
      const { value: extractedText } = await mammoth.extractRawText({
        buffer: Buffer.from(response.data),
      });

      // Convert extracted text to JSON format
      const lines = extractedText.split("\n").filter((line) => line.trim() !== "");
      const jsonResult = lines.map((line, index) => ({
        id: index + 1,
        text: line.trim(),
      }));

      // Update state with the JSON data
      setJsonData(jsonResult);

      // Log the JSON data
      console.log("Extracted JSON Data:", jsonResult);
    } catch (error) {
      console.error("Error extracting data:", error);
      setError("Failed to extract data. Please ensure the URL points to a valid Word file.");
    }
  };

  const dispatch = useAppDispatch();
  const editExamData = useAppSelecter(
    (state: ObjectValues) => state.exams.single_questions
  );
  const mergePaylod = (id: any) => {
    function makePropertiesWritable(obj: {
      [x: string]: any;
      hasOwnProperty: (arg0: string) => any;
    }) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // If the property is an object, recursively make its properties writable
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach(
                (item: {
                  [x: string]: any;
                  hasOwnProperty: (arg0: string) => any;
                }) => {
                  makePropertiesWritable(item);
                }
              );
            } else {
              makePropertiesWritable(obj[key]);
            }
          }
          // Make the property writable and configurable if it's not already defined
          if (!Object.getOwnPropertyDescriptor(obj, key)) {
            Object.defineProperty(obj, key, {
              writable: true,
              configurable: true,
            });
          }
        }
      }
    }

    const single_exam =
      editExamData[id] ?? questionGettingEditted?.question?.res ?? undefined;
    let newSubmittedData: any = {
      cluster: single_exam.cluster,
      explanation: single_exam.explanation,
      id: single_exam.id,
      is_active: single_exam.is_active,
      options: single_exam.options,
      status: single_exam.status,
      tag: single_exam.tag,
      translation: single_exam.translation,
    };
    makePropertiesWritable(newSubmittedData);

    allInputs.map((singleInput: inputProp) => {
      const queLanguage = submittedData[`Question LanguageId`];
      switch (singleInput.label) {
        case "Question Language":
          newSubmittedData = {
            ...newSubmittedData,
            default_language: submittedData.default_languge
              ? queLanguage
              : single_exam.default_language,
          };
          break;
        case "Question Tags":
          newSubmittedData = {
            ...newSubmittedData,
            tag: submittedData[singleInput.label],
          };

          break;
        case "Explanation":
          let objs =
            Array.isArray(newSubmittedData.explanation?.translation) &&
            newSubmittedData.explanation?.translation.length > 0
              ? newSubmittedData.explanation?.translation.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.explanation?.translation;
          newSubmittedData = {
            ...newSubmittedData,
            explanation: {
              ...newSubmittedData.explanation,
              translation: {
                ...objs,
                language: queLanguage,
                text: submittedData[singleInput.label],
              },
            },
          };

          break;
        case "Option A":
          let option: any =
            Array.isArray(newSubmittedData.options[0]?.translation) &&
            newSubmittedData.options[0]?.translation.length > 0
              ? newSubmittedData.options[0]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[0]?.translation;

          const objOpt = {
            is_correct:
              submittedData[`correct_answer`].label === singleInput.label,
            translation: {
              ...option,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 0 ? { ...opt, ...objOpt } : opt;
            }),
          };

          break;
        case "Option B":
          let option1: any =
            Array.isArray(newSubmittedData.options[1]?.translation) &&
            newSubmittedData.options[1]?.translation.length > 0
              ? newSubmittedData.options[1]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[1]?.translation;
          const objOpt1 = {
            is_correct:
              submittedData[`correct_answer`].label === singleInput.label,
            translation: {
              ...option1,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 1 ? { ...opt, ...objOpt1 } : opt;
            }),
          };

          break;
        case "Option C":
          let option2: any =
            Array.isArray(newSubmittedData.options[2]?.translation) &&
            newSubmittedData.options[2]?.translation.length > 0
              ? newSubmittedData.options[2]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[2]?.translation;

          const objOpt2 = {
            is_correct:
              submittedData[`correct_answer`].label === singleInput.label,
            translation: {
              ...option2,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 2 ? { ...opt, ...objOpt2 } : opt;
            }),
          };

          break;
        case "Option D":
          let option3: any =
            Array.isArray(newSubmittedData.options[3]?.translation) &&
            newSubmittedData.options[3]?.translation.length > 0
              ? newSubmittedData.options[3]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[3]?.translation;
          const objOpt3 = {
            is_correct:
              submittedData[`correct_answer`].label === singleInput.label,
            translation: {
              ...option3,
              language: queLanguage,
              text: submittedData[singleInput.label],
            },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 3 ? { ...opt, ...objOpt3 } : opt;
            }),
          };
          break;
        case "Question":
          let quest: any =
            Array.isArray(newSubmittedData?.translation) &&
            newSubmittedData?.translation.length > 0
              ? newSubmittedData?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData?.translation;
          if (quest) {
            quest = {
              ...quest,
              language: queLanguage,
              text: submittedData[singleInput.label],
            };
            newSubmittedData = { ...newSubmittedData, translation: quest };
          }
          break;
      }

      return singleInput;
    });
    return newSubmittedData;
  };
  const [questionGettingEditted, setQuestionGettingEditted] =
    useState<ObjectValues>({});
  const formatForSingleLanguage = (res: any, language: any) => {
    function makePropertiesWritable(obj: {
      [x: string]: any;
      hasOwnProperty: (arg0: string) => any;
    }) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // If the property is an object, recursively make its properties writable
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach(
                (item: {
                  [x: string]: any;
                  hasOwnProperty: (arg0: string) => any;
                }) => {
                  makePropertiesWritable(item);
                }
              );
            } else {
              makePropertiesWritable(obj[key]);
            }
          }
          // Make the property writable and configurable if it's not already defined
          if (!Object.getOwnPropertyDescriptor(obj, key)) {
            Object.defineProperty(obj, key, {
              writable: true,
              configurable: true,
            });
          }
        }
      }
    }

    const single_exam = res;
    let newSubmittedData: any = single_exam;
    makePropertiesWritable(newSubmittedData);

    allInputs.map((singleInput: inputProp) => {
      const queLanguage = language;
      switch (singleInput.label) {
        case "Explanation":
          let objs =
            Array.isArray(newSubmittedData.explanation?.translation) &&
            newSubmittedData.explanation?.translation.length > 0
              ? newSubmittedData.explanation?.translation.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.explanation?.translation;
          newSubmittedData = {
            ...newSubmittedData,
            explanation: { ...newSubmittedData.explanation, translation: objs },
          };

          break;
        case "Option A":
          let option: any =
            Array.isArray(newSubmittedData.options[0]?.translation) &&
            newSubmittedData.options[0]?.translation.length > 0
              ? newSubmittedData.options[0]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[0]?.translation;

          const objOpt = {
            translation: { ...option },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 0 ? { ...opt, ...objOpt } : opt;
            }),
          };

          break;
        case "Option B":
          let option1: any =
            Array.isArray(newSubmittedData.options[1]?.translation) &&
            newSubmittedData.options[1]?.translation.length > 0
              ? newSubmittedData.options[1]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[1]?.translation;
          const objOpt1 = {
            translation: { ...option1 },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 1 ? { ...opt, ...objOpt1 } : opt;
            }),
          };

          break;
        case "Option C":
          let option2: any =
            Array.isArray(newSubmittedData.options[2]?.translation) &&
            newSubmittedData.options[2]?.translation.length > 0
              ? newSubmittedData.options[2]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[2]?.translation;

          const objOpt2 = {
            translation: { ...option2 },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 2 ? { ...opt, ...objOpt2 } : opt;
            }),
          };

          break;
        case "Option D":
          let option3: any =
            Array.isArray(newSubmittedData.options[3]?.translation) &&
            newSubmittedData.options[3]?.translation.length > 0
              ? newSubmittedData.options[3]?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData.options[3]?.translation;
          const objOpt3 = {
            translation: { ...option3 },
          };
          newSubmittedData = {
            ...newSubmittedData,
            options: newSubmittedData.options.map((opt: any, i: number) => {
              return i === 3 ? { ...opt, ...objOpt3 } : opt;
            }),
          };
          break;
        case "Question":
          let quest: any =
            Array.isArray(newSubmittedData?.translation) &&
            newSubmittedData?.translation.length > 0
              ? newSubmittedData?.translation?.find(
                  (item: { language: any }) => item.language === queLanguage
                )
              : newSubmittedData?.translation;
          if (quest) {
            quest = { ...quest };
            newSubmittedData = { ...newSubmittedData, translation: quest };
          }
          break;
      }

      return singleInput;
    });
    return newSubmittedData;
  };
  const handleFileImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target?.result as string);
          setSubmittedData((prevData) => ({
            ...prevData,
            ...importedData,
          }));
          dispatch(
            showSnack({
              message: "Data imported successfully",
              severity: "success",
              open: true,
            })
          );
        } catch (error) {
          console.error("Error parsing imported file:", error);
          dispatch(
            showSnack({
              message: "Error importing file. Please make sure it's a valid JSON file.",
              severity: "error",
              open: true,
            })
          );
        }
      };
      reader.readAsText(file);
    }
  };
  const fomratQuestionData = (id: any, que?: any, language?: any) => {
    const single_exam = que ?? editExamData[id] ?? undefined;
    const current_language =
      single_exam?.translation?.language ??
      single_exam.default_language ??
      language ??
      "";
    const newSubmittedData: any = submittedData;
    allInputs.map((singleInput: inputProp) => {
      if (singleInput.defaultValue) {
        newSubmittedData[singleInput.label] = singleInput.defaultValue.value
          ? singleInput.defaultValue.value
          : "";

        newSubmittedData[`${singleInput.label}Id`] = singleInput.defaultValue.id
          ? singleInput.defaultValue.id
          : "";
      } else if (single_exam) {
        switch (singleInput.label) {
          case "Question Language":
            newSubmittedData[singleInput.label] =
              singleInput.list?.find((it) => it.id === current_language)
                ?.label ?? "";
            newSubmittedData[`${singleInput.label}Id`] = current_language;
            newSubmittedData.default_languge =
              current_language === single_exam.default_language;
            break;
          case "Question Tags":
            newSubmittedData[singleInput.label] = single_exam.tag
              ? singleInput.list?.filter((item) =>
                  single_exam.tag
                    .map((ss: any) => ss.id ?? ss)
                    .includes(item.id)
                )
              : [];

            break;
          case "Explanation":
            newSubmittedData[singleInput.label] =
              single_exam.explanation?.translation.length > 0
                ? single_exam.explanation?.translation.find(
                    (item: { language: any }) =>
                      item.language === newSubmittedData[`Question LanguageId`]
                  )?.text ?? ""
                : single_exam.explanation?.translation?.text ?? "";

            break;
          case "Option A":
            newSubmittedData[singleInput.label] =
              single_exam.options[0]?.translation.length > 0
                ? single_exam.options[0]?.translation.find(
                    (item: { language: any }) =>
                      item.language === newSubmittedData[`Question LanguageId`]
                  )?.text ?? ""
                : single_exam.options[0]?.translation?.text ?? "";
            if (single_exam.options[0]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option B":
            newSubmittedData[singleInput.label] =
              single_exam.options[1]?.translation.length > 0
                ? single_exam.options[1]?.translation.find(
                    (item: { language: any }) =>
                      item.language === newSubmittedData[`Question LanguageId`]
                  )?.text ?? ""
                : single_exam.options[1]?.translation?.text ?? "";
            if (single_exam.options[1]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option C":
            newSubmittedData[singleInput.label] =
              single_exam.options[2]?.translation.length > 0
                ? single_exam.options[2]?.translation.find(
                    (item: { language: any }) =>
                      item.language === newSubmittedData[`Question LanguageId`]
                  )?.text ?? ""
                : single_exam.options[2]?.translation?.text ?? "";
            if (single_exam.options[2]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Option D":
            newSubmittedData[singleInput.label] =
              single_exam.options[3]?.translation.length > 0
                ? single_exam.options[3]?.translation.find(
                    (item: { language: any }) =>
                      item.language === newSubmittedData[`Question LanguageId`]
                  )?.text ?? ""
                : single_exam.options[3]?.translation?.text ?? "";
            if (single_exam.options[3]?.is_correct) {
              newSubmittedData[`correct_answer`] = singleInput;
            }
            break;
          case "Question":
            newSubmittedData[singleInput.label] =
              Array.isArray(single_exam.translation) &&
              single_exam.translation.length > 0
                ? single_exam.translation?.find(
                    (item: { language: any }) =>
                      item.language === newSubmittedData[`Question LanguageId`]
                  )?.text ?? ""
                : single_exam?.translation?.text ?? "";

            break;
        }
      }
      return singleInput;
    });
    if (language) {
      return {
        res: formatForSingleLanguage(single_exam, language),
        form: newSubmittedData,
      };
    } else {
      setSubmittedData(newSubmittedData);
    }
  };
  const [selectedStep, setStep] = useState<number>(1);
  const [stepResponse, setStepResponse] = useState<ObjectValues>({});
  const firstStep =
    inputSteps && inputSteps.length > 0
      ? inputSteps.find((s) => s.id === selectedStep)
      : undefined;
  const allInputs = firstStep
    ? firstStep.inputFormArray ?? []
    : inputFormArray ?? [];
  const [submittedData, setSubmittedData] = useState<ObjectValues>({});
  const [questionArray, setQuestion] = useState<ObjectValues[]>([]);
  // useEffect(() => {
  //   if (oldSubmittedData) {
  //     setSubmittedData(oldSubmittedData);
  //   }
  // }, [oldSubmittedData]);
  const userData = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );

  const content_list = useAppSelecter(
    (state: ObjectValues) => state.exams.content_list
  );

  function renderTextField({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    multiline,
    disabled,
    type,
    dataType,
    only_number,
  }: inputProp) {
    return (
      <CustomTextField
        placeholder={placeholder}
        disabled={submittedData.loader === true ? true : disabled ?? false}
        id={label}
        onKeyDown={(evt) => {
          if (only_number === true) {
            evt.key === "." && evt.preventDefault();
          }
          if (dataType === "number") {
            evt.key === "e" && evt.preventDefault();

            evt.key === "E" && evt.preventDefault();
            evt.key === "-" && evt.preventDefault();
            evt.keyCode === 38 && evt.preventDefault();
            evt.keyCode === 40 && evt.preventDefault();
          }
        }}
        onFocus={dataType === "number" ? StopScroll : () => {}}
        variant="outlined"
        error={error}
        type={dataType === "number" ? "number" : "text"}
        helperText={helperText}
        value={submittedData[label] ?? ""}
        maxRows={multiline ?? 1}
        minRows={multiline ?? 1}
        multiline={multiline ? true : false}
        onChange={(e) => {
          handleInputChange(label, e.target.value);
        }}
        onKeyPress={async (ev: any) => {
          if (ev.key === "Enter") {
            ev.preventDefault();

            // submit();
          }
        }}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": { padding: "0" },
          "& .MuiInputBase-input": multiline
            ? {
                padding: "10px 12px",
              }
            : {},
        }}
        InputProps={{
          endAdornment,
        }}
        inputProps={{
          // type: details.visible ? "" : type && "password",
          "data-testid": `${label}-input`, // Test ID for testing purposes
        }}
      />
    );
  }
  const fieldCountAndFinalPayload = () => {
    let submitData: ObjectValues = {}; //new FormData();
    const correctData: any = [];
    allInputs
      .filter((sA) => sA.api)
      .map((ss: inputProp) => {
        const { api, label, not_required, not_send_id, fieldType } = ss;
        if (
          // (singleInput.type === "checkBox" &&
          //   (submittedData[singleInput.label] === false ||
          //     submittedData[singleInput.label] === true)) ||
          // (singleInput.type === "toggle" &&
          //   (submittedData[singleInput.label] === false ||
          //     submittedData[singleInput.label] === true)) ||
          not_required === true ||
          submittedData[label]
        ) {
          correctData.push(label);

          // if (freeSolo) {
          //   if (submittedData[label] && submittedData[label].length > 0&&api) {
          //     submitData[api] = submittedData[label];
          //   }
          // }
          if (api) {
            submitData[api] = not_send_id
              ? submittedData[label]
              : submittedData[`${label}Id`]
              ? submittedData[`${label}Id`] === "-"
                ? null
                : submittedData[`${label}Id`]
              : fieldType === "date" && submittedData[label]
              ? moment(new Date(submittedData[label])).format("YYYY-MM-DD")
              : submittedData[label];
          }
        }
        //   } else {
        //     var errorMsg = this.state.errorMsg;
        //     errorMsg[singleInput.name] = `Wrong ${singleInput.name} !`;
        //     return this.setState({ errorMsg });
        //   }
        // } else {
        //   var errorMsg = this.state.errorMsg;
        //   errorMsg[singleInput.name] = singleInput.validate
        //     ? singleInput.validate(submittedData) === true
        //       ? ""
        //       : "Password do not match !"
        //     : `Invalid ${singleInput.name} !`;
        //   return this.setState({ errorMsg });
        // }
        // }
        // else {
        //   var errorMsg = this.state.errorMsg;
        //   errorMsg[singleInput.name] = `Please ${singleInput.type === "select" ? "select" : "add"} ${
        //     singleInput.name
        //   } !`;
        //   return this.setState({ errorMsg });
        // }
        return ss;
      });
    return { final: submitData, available: correctData };
  };
  const checkAllFields = () => {
    const { available, final } = fieldCountAndFinalPayload();
    return submittedData.correct_answer &&
      submittedData.correct_answer !== null &&
      allInputs.filter((sA) => sA.api).length === available.length
      ? { ...final, correct_answer: submittedData.correct_answer.label }
      : null;
  };
  function renderDropDown({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    listLabel,
    type,
    list,
    disabled,
  }: inputProp) {
    return (
      <CustomTextField
        required={true}
        disabled={submittedData.loader === true ? true : disabled ?? false}
        // error={errorMsg[singleInput.name] ? true : false}
        error={error}
        helperText={helperText}
        autoComplete="off"
        value={submittedData[label] ?? ""}
        sx={{ width: "100%" }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                margin: "1px 0 0 2px",
                borderRadius: "10px", // Customize the border-radius for the dropdown paper
              },
            },
          },
        }}
        // submittedData[singleInput.name]}
        // style={
        //   textFieldStyle
        //     ? textFieldStyle
        //     : textFieldWidth
        //     ? styles.withDivider502
        //     : styles.withDivider50
        // }
        id="outlined-select-currency-native"
        select
        // label={"qq"}
        // singleInput.name.charAt(0).toUpperCase() + singleInput.name.slice(1)}
        // SelectProps={{ disableunderline: "true" }}
        variant="outlined"
        onChange={(e) => {
          // handleInputChange(label, e.target.value);
          if (e.target.value) {
            const id = `${label}Id`;
            // const submitted :any= {};
            // submitted[label] = e.target.value;
            // // var errorMsg = errorMsg;
            // // errorMsg[label] = false;
            // submitted[id] = e.currentTarget.id;
            setSubmittedData((prevDetails) => ({
              ...prevDetails,
              [label]: e.target.value,
              [id]: e.currentTarget?.id ?? "",
              [`${label}-error`]: undefined,
            }));
          }
          //     if (singleInput.onChange) {
          //       singleInput
          //         .onChange(
          //           submittedData,
          //           () => {
          //             this.setState({ loading: true });
          //           },
          //           () => {
          //             this.setState({ loading: false });
          //           }
          //         )
          //         .then((submittedData) => {
          //           this.setState({
          //             submittedData,
          //             errorMsg,
          //           });
          //         });
          //     } else {
          //       this.setState({
          //         submittedData,
          //         errorMsg,
          //       });
          //     }
          //   }
        }}
      >
        {list?.map((item, i) => (
          <MenuItem
            //   disabled={true}
            key={`${item[listLabel ?? "label"]}==${i}`}
            value={item[listLabel ?? "label"]}
            id={item.id ?? item[listLabel ?? "label"]}
            sx={{ textTransform: "capitalize" }}
          >
            {item[listLabel ?? "label"]}
          </MenuItem>
        ))}
      </CustomTextField>
    );
  }
  useEffect(() => {
    if (editData && editExamData[editData.id]) {
      fomratQuestionData(editData.id);

      const all_languages = editData.translation
        ? editData.translation.map((s: { language: any }) => s.language)
        : [];

      if (all_languages.length > 0) {
        const queArr: any = [];
        all_languages.map((language: any) => {
          if (language) {
            queArr.push(fomratQuestionData(editData.id, null, language));
          }
          return language;
        });

        const defaultLang = queArr.find(
          (ss: {
            res: { default_language: any; translation: { language: any } };
          }) => ss.res?.default_language === ss.res?.translation?.language
        );

        setQuestion(queArr);
        setQuestionGettingEditted({ question: defaultLang ?? queArr[0] });
      }
    }
  }, [editExamData]);
  useEffect(() => {
    // getApi
    if (editData?.id) {
      dispatch(
        fetchSinlgQustion({
          api: getApi(editData?.id),
          method: "GET",
          payload: {},
          id: editData?.id,
        })
      );
    }
    if (!oldSubmittedData) {
      const newSubmittedData: any = submittedData;
      allInputs.map((singleInput: inputProp) => {
        if (singleInput.defaultValue) {
          newSubmittedData[singleInput.label] = singleInput.defaultValue.value
            ? singleInput.defaultValue.value
            : "";

          newSubmittedData[`${singleInput.label}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";
        } else if (!newSubmittedData[singleInput.label] && editData) {
          switch (singleInput.label) {
            case "Question Language":
              newSubmittedData[singleInput.label] =
                singleInput.list?.find(
                  (it) => it.id === editData.primary_language
                )?.id ?? "";
              newSubmittedData[`${singleInput.label}Id`] =
                editData.primary_language;
              break;
            case "Question Tags":
              newSubmittedData[singleInput.label] = editData.tag
                ? singleInput.list?.filter((item) =>
                    editData.tag.map((ss: any) => ss.id ?? ss).includes(item.id)
                  )
                : [];
              break;
            case "Explanation":
              newSubmittedData[singleInput.label] =
                editData.explanation?.text ?? "";
              break;
            case "Option A":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Option B":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Option C":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Option D":
              newSubmittedData[singleInput.label] = singleInput.list?.filter(
                (item) => (editData.tag ? editData.tag.includes(item.id) : [])
              );
              break;
            case "Question":
              newSubmittedData[singleInput.label] =
                editData.translation?.text ?? "";
              // newSubmittedData["correct_answer"]=singleInput

              break;
          }
        }
        return singleInput;
      });
      setSubmittedData(newSubmittedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  const initialValue = (res?: any) => {
    if (!oldSubmittedData) {
      const newSubmittedData = res ? {} : submittedData;
      allInputs.map((singleInput: inputProp) => {
        if (!newSubmittedData[singleInput.label]) {
          if (singleInput.defaultValue) {
            newSubmittedData[singleInput.label] = singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

            newSubmittedData[`${singleInput.label}Id`] = singleInput
              .defaultValue.id
              ? singleInput.defaultValue.id
              : "";
          } else {
            return (newSubmittedData[singleInput.label] = "");
          }
        }
        return singleInput;
      });
      if (res) {
        const oldVal =
          questionArray.length > 0
            ? questionArray[0].res.tag.map((ss: { id: any }) => {
                return ss.id ?? ss;
              })
            : res.tag.map((ss: { id: any }) => {
                return ss.id ?? ss;
              }) ?? [];
        const tagInput = allInputs?.find(
          (item) => item.label === "Question Tags"
        );
        setSubmittedData({
          ...newSubmittedData,
          ...{
            res: res,
            ["Question Tags"]: oldVal
              ? tagInput?.list?.filter((item) => oldVal.includes(item.id))
              : [],
          },
        });
      } else {
        setSubmittedData({ ...newSubmittedData });
      }
    }
  };
  const insertStringAfterFirst3Chars = (
    input: string,
    insertString: string
  ): string => {
    if (input?.length <= 3) {
      // If the input string is 3 characters or less, just append the insertString to the input
      return input + insertString;
    }

    // Insert the string after the first 3 characters
    const firstPart = input?.slice(0, 3);
    const secondPart = input?.slice(3);
    return firstPart + insertString + secondPart;
  };
  const getOptionsText = (data: string, option: string): string => {
    const optionText = insertStringAfterFirst3Chars(data, option);
    return optionText;
  };
  useEffect(() => {
    initialValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line
  const [fileInput_ref, setFileInputRef] = useState<any>({});
  const handleInputChange = (
    label: string,
    value: string | number | inputProp | null | boolean,
    id?: any
  ) => {
    setSubmittedData((prevDetails) => ({
      ...prevDetails,
      [label]: value,
      [`${label}Id`]: id,
      [`${label}-error`]: undefined,
    }));
  };
  const submit = async (type?: "exit") => {
    const submitData: any = checkAllFields();
    if (submitData !== null) {
      setSubmittedData((pre) => {
        return { ...pre, loader: true, clicked_button_type: type };
      });
      if (extraFields && extraFields.length > 0) {
        extraFields.map((sField: { label: string | number; value: any }) => {
          submitData[sField.label] = sField.value;
          return sField;
        });
      }
      const response: any = await apiRequest({
        api:
          editData || questionGettingEditted?.question?.res?.id
            ? `cms/content/question/update/`
            : api.create,
        payload:
          editData || questionGettingEditted?.question?.res?.id
            ? mergePaylod(
                editData?.id ?? questionGettingEditted?.question?.res?.id ?? ""
              )
            : formatPayload
            ? formatPayload(
                submitData,
                questionArray.length > 0
                  ? questionArray[0].res.id
                  : submittedData.res ?? null
              )
            : submitData,
        method:
          editData || questionGettingEditted?.question?.res?.id
            ? "PUT"
            : "POST",
      });
      if (response === undefined || (response && response.error)) {
        await setSubmittedData((p) => {
          return { ...p, loader: false };
        });
      } else {
        const newArr: any = questionArray.map((ss) => {
          return {
            ...ss,
            res: {
              ...ss.res,
              default_language: response.default_language,
              tag: response.tag ?? [],
            },
          };
        });

        if (
          newArr.find(
            (item: { res: { translation: { id: any } } }) =>
              item.res.translation.id === response.translation.id
          )
        ) {
          const index = newArr.findIndex(
            (item: { res: { translation: { id: any } } }) =>
              item.res.translation.id === response.translation.id
          );

          if (index !== -1) {
            const updatedItem = {
              ...newArr[index],
              res: response,
            }; // Replace the `res` object with the new value
            newArr.splice(index, 1, updatedItem); // Replace the old object with the updated one
          }
        } else {
          newArr.push({ res: response, form: submittedData });
        }
        setQuestion(newArr);
        await dispatch(
          showSnack({
            message: successMessage(response) ?? "",
            severity: "success",
            open: true,
          })
        );
        initialValue(response);
        setQuestionGettingEditted({});
        if (type) {
          if (selectedStep === 0 && firstStep) {
            setStepResponse(response);
            setStep(1);
          } else {
            await onClear([response]);
          }
        }
      }
    }
  };
  const oneContentWidth = {
    maxWidth: "100%",
    width: "100%",
  };
  const fullWidthSx = { width: "100%" };
  const singleForm: any = (input: inputProp, i: number) => {
    const headingContent = subHeading({
      text: `${input.label} ${input.not_required === true ? "" : "*"}`,
    });
    const sx = input.fullWidth ? fullWidthSx : oneContentWidth;
    switch (input.fieldType) {
      case "image":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <Box
              sx={{
                border: `1px solid ${globalColors.primary.grey}`, // mostLightGrey, // sets the border color to purple
                borderRadius: "10px",
                padding: "0.69px 0.69px 0.69px 12px",
                color: globalColors.primary.black,
                ...rowSB,
              }}
            >
              {" "}
              {subHeading({
                text:
                  submittedData[input.label] && submittedData[input.label].file
                    ? `<img src="${
                        submittedData[input.label].file
                      }" alt="image" />`
                    : "",
                parentSx: { flex: 8, width: "calc(100% - 160px)" },
                sx: {
                  whiteSpace: "nowrap",
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              })}{" "}
              <input
                accept="image"
                ref={(ref) => {
                  setFileInputRef(ref);
                }}
                multiple
                id="files"
                style={{
                  visibility: "hidden",
                  width: "0",
                  maxHeight: "11px",
                }}
                type="file"
                onChange={(e: {
                  preventDefault: () => void;
                  stopPropagation: () => void;
                  target: { files: any };
                }) => {
                  e.preventDefault();
                  e.stopPropagation();

                  let fileObj = [];
                  const fileA: any[] = [];
                  fileObj.push(e.target.files);
                  for (let i = 0; i < fileObj[0].length; i++) {
                    fileA.push(fileObj[0][i]);
                  }
                  const fileUpload = async () => {
                    const res = await apiRequest({
                      form: true,
                      api: allApis.file_upload,
                      payload: {
                        parent_id: submittedData["Parent ClusterId"],
                        file: fileA[0],
                        created_by: userData?.id,
                        modified_by: userData?.id,
                        parent_model:
                          content_list.find(
                            (item: { model: string }) =>
                              item.model === "question"
                          )?.id ?? "",
                      },
                    });

                    handleInputChange(input.label, res ?? fileA[0]);
                  };
                  fileUpload();
                }}
              />
              <Box sx={{ padding: "0 7px" }}>
                <CustomIconButton
                  content={copyIcon}
                  noHover={true}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `<img src="${
                        submittedData[input.label].file
                      }" alt="image" />`
                    );

                    copy(
                      `<img src="${
                        submittedData[input.label].file
                      }" alt="image" />`,
                      {
                        debug: true,
                        onCopy: () => {
                          dispatch(
                            showSnack({
                              message:
                                "Image copied, paste it wherever you want it.",
                              severity: "success",
                              open: true,
                            })
                          );
                        },
                      }
                    );
                  }}
                />
              </Box>
              <label htmlFor="files" className="btn">
                <Box
                  sx={{
                    backgroundColor: globalColors.primary.black, // You can customize the background color here
                    color: globalColors.primary.white,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "0.875rem !important",
                    borderRadius: "8px",
                    lineHeight: "normal",
                    cursor: "pointer",
                    padding: "9px 10px",
                  }}
                >
                  Choose image
                </Box>
              </label>
              
            </Box>
          </Stack>
        );
      

      case "text":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {renderTextField(input)}
          </Stack>
        );
      case "checkbox":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
              alignItems: "flex-end",
              justifyContent: "center",
              pt: "26px",
            }}
          >
            {/* {headingContent} */}
            {/* <Box sx={{minHeight:"38.86px",alignItems:"center",display:"flex"}}>
             <Checkbox checked={false}onChange={()=>{

          }}/></Box> */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                margin: "0 10px 0 0 !important",
                alignItems: "center",
              }}
            >
              {subHeading({ text: input.label })}
              <FormControlLabel
                sx={{ margin: "0 0 0 0 !important" }}
                label=""
                control={
                  <Checkbox
                    sx={{
                      margin: "0 !important",
                      "&.MuiButtonBase-root": {
                        padding: "0",
                      },
                    }}
                    checked={submittedData["default_languge"] ? true : false}
                    onChange={(event: any) => {
                      handleInputChange(
                        "default_languge",
                        event.target.checked ? true : false
                      );
                    }}
                  />
                }
              />
            </Box>
          </Stack>
        );

      case "select":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {renderDropDown(input)}
          </Stack>
        );
      case "date":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <CustomDatePicker
              {...input}
              value={submittedData[input.label] ?? null}
              disabled={
                submittedData.loader === true ? true : input.disabled ?? false
              }
              onChange={(val: any) => {
                handleInputChange(input.label, val);
              }}
            />
          </Stack>
        );
      case "autocomplete":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {input.multiple ? (
              <MultipleAutoComplte
                {...input}
                value={submittedData[input.label] ?? []}
                onChange={(val: any) => {
                  handleInputChange(input.label, val);
                }}
                disabled={
                  // questionArray.length > 0
                  //   ? true
                  //   :
                  submittedData.loader === true ? true : input.disabled ?? false
                }
              />
            ) : (
              <CustomAutocomplete
                {...input}
                list={
                  input.list && input.list.length > 0
                    ? input.list.filter((li) => {
                        return !questionArray?.find(
                          ({ res }) =>
                            (res.translation.language ??
                              res.default_language) === li.id
                        );
                      })
                    : []
                }
                value={
                  input.list?.find(
                    (s) =>
                      s[input.listLabel ?? "label"] ===
                      submittedData[input.label]
                  ) ?? null
                }
                onChange={(val: any) => {
                  handleInputChange(
                    input.label,
                    val ? val[input.listLabel ?? "label"] : null,
                    val ? val.id : undefined
                  );
                }}
                disabled={
                  submittedData.loader === true ? true : input.disabled ?? false
                }
              />
            )}
          </Stack>
        );
      case "cktextEditor":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              marginBottom: "20.91px",
              ...sx,
              position: "relative",
            }}
          >
            {headingContent}
            {input.dataType === "option" && (
              <Box
                sx={{
                  position: "absolute",
                  width: "105px",
                  right: "15px",
                  top: "35px",
                  zIndex: "9",
                  display: "flex",
                  gap: "10px",
                  margin: "0 !important",
                  alignItems: "center",
                }}
              >
                {subHeading({ text: "Is Answer" })}
                <FormControlLabel
                  sx={{ margin: "0 0 0 0 !important" }}
                  label=""
                  control={
                    <Checkbox
                      sx={{
                        margin: "0 !important",
                        "&.MuiButtonBase-root": {
                          padding: "0",
                        },
                      }}
                      checked={
                        submittedData["correct_answer"] &&
                        submittedData["correct_answer"] !== null &&
                        submittedData["correct_answer"].label === input.label
                          ? true
                          : false
                      }
                      onChange={(event: any) => {
                        handleInputChange(
                          "correct_answer",
                          event.target.checked ? input : null
                        );
                      }}
                    />
                  }
                />
              </Box>
            )}
            <Box>
              <CustomCkeditor
                {...input}
                value={submittedData[input.label] ?? ""}
                onChange={(val: any) => handleInputChange(input.label, val)}
              />
            </Box>
          </Stack>
        );
      case "extra":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              marginBottom: "20.91px",
              ...sx,
              // height: "100px",
            }}
          ></Stack>
        );
      default:
        return <Grid item xs={singlexs} display="flex" key={"default"}></Grid>;
    }
  };
  const singlexs = 6;
  const allFormComp: any = (list: inputProp[]) => {
    return list?.map((input: inputProp, i: number) => {
      return (
        <Grid
          item
          xs={input.fullWidth ? 12 : singlexs}
          display="flex"
          key={`${input.label}=${i}`}
          sx={
            input.fullWidth ? {} : { maxWidth: "calc(50% - 7.5px) !important" }
          }
          {...input.grid}
        >
          {singleForm(input, i)}
        </Grid>
      );
    });
  };
  console.log(questionArray, "questionArray");
  return (
    <Stack height="100%" width="100%" display="flex" flexDirection="row">
      <Stack
        spacing={"13px"}
        height="100%"
        justifyContent={"space-between"}
        overflow="hidden"
        sx={{ width: "60%" }}
      >
        <Stack spacing={"20px"} sx={{ overflow: "hidden", flex: "8" }}>
          <Box
            sx={{
              ...rowSB,
              borderBottom: `1px solid ${globalColors.primary.borderColor}`,
              alignItems: "center",
              p: "0 10px 10px",
            }}
          >
            {inputSteps && inputSteps.length > 0 ? (
              <Box sx={{ ...displayRow, gap: "55px" }}>
                {inputSteps.map(({ label }, i: number) => {
                  const activeStep = selectedStep === i;
                  const pastStep = selectedStep > i;
                  return (
                    <Box
                      sx={{
                        ...displayRow,
                        gap: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "50%",
                          height: "23px",
                          width: "23px",
                          border: pastStep
                            ? ""
                            : `2px solid ${
                                activeStep
                                  ? globalColors.primary.green
                                  : "#CDCDCD"
                              }`,
                          ...rowCenter,
                          fontSize: "0.9rem",
                          fontWeight: 500,
                          background: pastStep
                            ? globalColors.primary.green
                            : "",
                        }}
                      >
                        {pastStep ? rightIcons : i}
                      </Box>
                      {headerContent({
                        size: "1.1rem",
                        text: label,
                        weight: "500",
                        sx: {
                          textTransform: "capitalize",
                          width: "auto",
                          color:
                            activeStep || pastStep
                              ? globalColors.primary.black
                              : globalColors.primary.normalText,
                        },
                        icons: [],
                      })}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              headerContent({
                size: "1.1rem",
                text: fromCluster
                  ? "Add Question"
                  : `${editData ? "Edit" : "Add New"} ${header}`,
                weight: "500",
                sx: {
                  color: globalColors.primary.black,
                  textTransform: "capitalize",
                  width: fromCluster ? "100%" : "auto",
                },
                icons: fromCluster
                  ? [
                      {
                        content: <SvgPreview svg={cross} />,
                        onClick: () => {
                          onClear(questionArray);
                        },
                        sx: { padding: "0" },
                      },
                    ]
                  : [],
              })
            )}
          </Box>
          {questionArray.map((item) => {
            const { res } = item;
            const isSelected =
              questionGettingEditted?.question?.res?.translation.id ===
              res?.translation.id;

            return (
              <Box
                sx={{
                  border: `1px solid ${globalColors.primary.grey}`,
                  borderRadius: "10px",
                  padding: "10px 12px",
                  color: globalColors.primary.black,
                  ...rowSB,
                  background: isSelected ? "rgb(0 0 0 / 3%)" : "",
                  pointerEvents: isSelected ? "none" : "inherit",
                }}
              >
                <Box
                  sx={{
                    flex: 8,
                    width: "calc(100% - 116px)",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "flex",
                  }}
                >
{/* import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
  
  );
} */}



 
                  <Box sx={{ ...displayColumn, width: "120px" }}>
                    {subHeading({
                      size: "0.75rem",
                      sx: { color: globalColors.primary.normalText },
                      text: `Language ${
                        res.explanation?.translation?.language ===
                        res.default_language
                          ? "(Default)"
                          : ""
                      }`,
                    })}
                    <Box>
                      {" "}
                      {subHeading({
                        size: "0.75rem",
                        sx: { color: globalColors.primary.black },
                        text:
                          res.explanation?.translation?.language ??
                          res.default_language,
                      })}
                    </Box>
                  </Box>
                  <Box sx={{ ...displayColumn, width: "calc(50% - 60px)" }}>
                    {subHeading({
                      size: "0.75rem",
                      sx: { color: globalColors.primary.normalText },
                      text: "Question",
                    })}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: res.translation?.text ?? "",
                      }}
                    />
                  </Box>{" "}
                  <Box sx={{ ...displayColumn, width: "calc(50% - 60px)" }}>
                    {subHeading({
                      size: "0.75rem",
                      sx: { color: globalColors.primary.normalText },
                      text: "Explanation",
                    })}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: res.explanation?.translation?.text ?? "",
                      }}
                    />
                  </Box>
                </Box>

                <CustomIconButton
                  content="Edit"
                  noHover={true}
                  className="linkStyle"
                  onClick={() => {
                    // setSubmittedData(form);
                    if (item.res.id) {
                      fomratQuestionData(item.res.id, item.res);
                    }
                    setQuestionGettingEditted({ question: item });
                  }}
                />
              </Box>
            );
          })}
          {questionArray.length > 0 && (
            <Box
              className="dashed-border"
              sx={{ width: "100%", margin: "10px 0" }}
            ></Box>
          )}

          <Stack
            direction={"row"}
            spacing={"10px"}
            className="scrollBluePrimarythin"
            sx={{ overflowY: "auto", flex: "8" }}
          >
            <Grid
              container
              width="100%"
              key={"QuestionFormComponent"}
              rowSpacing={1}
              columnGap={oldSubmittedData ? "15px" : ""}
              sx={{ gap: "0 15px" }}
            >
              {/* wwimage<img src="http://test.netpractice.in/media/content/download_1.jpeg" alt="image" /> */}

              {allFormComp(allInputs)}
            </Grid>
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", ...rowSB }}>
          <CustomIconButton
            key="cancel"
            variant="outlined"
            content="cancel"
            sx={{ minWidth: "180px" }}
            onClick={() => {
              onClear(questionArray);
            }}
          />
          
          <Box>
          {/* <Input
              type="file"
              id="file-import"
              style={{ display: 'none' }}
              onChange={handleFileImport}
              inputProps={{ accept: '.json' }}
            />
            <label htmlFor="file-import">
              <CustomIconButton
                key="import"
                variant="outlined"
                content="Import from File"
                sx={{
                  minWidth: "180px",
                  marginRight: "15px",
                }}
                component="span"
              />
            </label> */}
{/* 
<React.Fragment>
      <CustomIconButton variant="outlined" content="Import from File" onClick={handleClickOpen} component="span"/>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent
        >
          <DialogContentText>
          <input
        type="text"
        placeholder="Enter Word file URL"
        value={fileUrl}
        onChange={(e) => setFileUrl(e.target.value)}
        style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
      />
      <button onClick={handleExtractData} style={{ padding: "10px 20px" }}>
        Extract Data
      </button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div style={{ marginTop: "20px" }}>
        <h2>Extracted JSON Data:</h2>
        <pre style={{ background: "#f4f4f4", padding: "10px" }}>
          {jsonData ? JSON.stringify(jsonData, null, 2) : "No data extracted yet."}
        </pre>
        </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment> */}

            <CustomIconButton
              onClick={() => {
                submit();
              }}
              disabled={
                checkAllFields() === null ? true : submittedData.loader ?? false
              }
              key="Add Question in Another Language"
              variant="outlined"
              content="Add Question in Another Language"
              icon={Addicon}
              sx={{ maxWidth: "fit-content" }}
            />{" "}
            <CustomContainedButton
              otherProps={{
                disabled:
                  checkAllFields() === null
                    ? true
                    : submittedData.loader ?? false,
                onClick: () => {
                  submit("exit");
                },
                sx: { minWidth: "180px" },
              }}
              loading={
                submittedData.loader && submittedData.clicked_button_type
                  ? submittedData.loader
                  : undefined
              }
              content={
                selectedStep === 0 && firstStep
                  ? "Save and Next"
                  : fromCluster
                  ? "Create"
                  : "Save and Exit"
              }
            />
          </Box>
        </Box>
      </Stack>{" "}
      <Divider orientation="vertical" variant="middle" flexItem />
      <Grid
        container
        width="40%"
        key={"QuestionFormComponentMock"}
        rowSpacing={1}
        columnGap={oldSubmittedData ? "15px" : ""}
        className="scrollBluePrimarythin"
        style={{ padding: "20px 20px 20px 10px" }}
        sx={{
          gap: "0 15px",
          height: "100%",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Paper elevation={5} square={false} style={{ width: "100%", }}>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            Question Preview
          </div>

          <Stack style={{ padding: "20px 30px 0px 30px" }}>
            <PreviewComponent htmlContent={submittedData["Question"]} />
            {/* <div dangerouslySetInnerHTML={{ __html: submittedData["Question"] }} style={{ paddingTop: "15px", fontSize: "15px" }}></div> */}
            <div style={{ paddingTop: "15px" }}>
              {(submittedData["Option A"]?.length > 0 ||
                submittedData["Option B"]?.length > 0 ||
                submittedData["Option C"]?.length > 0 ||
                submittedData["Option D"]?.length > 0) && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "15px",
                      fontWeight: "100",
                      border: "1px solid grey",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <PreviewComponent
                      htmlContent={getOptionsText(
                        submittedData["Option A"],
                        "A. "
                      )}
                    />
                    {/* <div dangerouslySetInnerHTML={{ __html: getOptionsText(submittedData["Option A"], "A. ") }}></div> */}
                    {submittedData["correct_answer"]?.label === "Option A" && (
                      <CheckCircleIcon style={{ color: "green" }} />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "15px",
                      fontWeight: "100",
                      border: "1px solid grey",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <PreviewComponent
                      htmlContent={getOptionsText(
                        submittedData["Option B"],
                        "B. "
                      )}
                    />
                    {submittedData["correct_answer"]?.label == "Option B" && (
                      <CheckCircleIcon style={{ color: "green" }} />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "15px",
                      fontWeight: "100",
                      border: "1px solid grey",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <PreviewComponent
                      htmlContent={getOptionsText(
                        submittedData["Option C"],
                        "C. "
                      )}
                    />
                    {submittedData["correct_answer"]?.label == "Option C" && (
                      <CheckCircleIcon style={{ color: "green" }} />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "15px",
                      fontWeight: "100",
                      border: "1px solid grey",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <PreviewComponent
                      htmlContent={getOptionsText(
                        submittedData["Option D"],
                        "D. "
                      )}
                    />
                    {submittedData["correct_answer"]?.label == "Option D" && (
                      <CheckCircleIcon style={{ color: "green" }} />
                    )}
                  </div>
                </>
              )}
              {submittedData["Explanation"]?.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <b>Explanation</b>
                  <div style={{ paddingTop: "15px", fontSize: "15px" }}>
                    <PreviewComponent
                      htmlContent={submittedData["Explanation"]}
                    />
                  </div>
                  {/* <div dangerouslySetInnerHTML={{ __html: submittedData["Explanation"] }} style={{ paddingTop: "15px", fontSize: "15px" }}></div> */}
                </div>
              )}
              {submittedData["Question Tags"]?.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <b>Question Tags</b>
                  <ul style={{ paddingTop: "5px" }}>
                    {submittedData["Question Tags"]?.map((d: any) => (
                      <Chip
                        sx={{
                          margin: "5px",
                          background: "#E3F4E7",
                          maxWidth: "calc(100% - 10px)",
                          "& .MuiChip-label": {
                            fontSize: "0.69srem",
                            lineHeight: "1rem",
                          },
                        }}
                        label={d.name}
                        variant="outlined"
                      />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Stack>
        </Paper>
      </Grid>
    </Stack>
  );
}

export default QuestionFormComponentExams
