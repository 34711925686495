import { allModules, allModulesInMockTest } from "../store/slices/constants/staticContents";

export const allApis: any = {
  login: "npc-admin/login/",
  verifyOtp: "npc-admin/login/verify-otp/",
  questionTags: {
    list: "cms/content/tag/list/",
    create: "cms/content/tag/create/",
  },
  file_upload:`cms/content/file/upload/`,
  user: "npc-admin/user/",
  path_order: "cms/utils/path_order_change/",
  content: "cms/utils/content_type/list/",
  all_clusters:"cms/content/cluster/list/",
  cluster_questions:(id:string)=>`cms/content/cluster/${id}`,
  status_create: "cms/utils/status_log/create/",
  status_log: (id: string, content_id: string) =>
    `cms/utils/status_log/list/?content_id=${id}&content_type_id=${content_id}`,
  examsTab: "cms/exam/list/",
  questionTab: (id: string) => `cms/content/question/list/?cluster_id=${id}`,
  questionMockTab: (id: string) => `cms/content/mock_test/question/status_count/?mock_test_section_id=${id}`,
  reportQuestion: (id: string) => `cms/content_feedback/reported_question/${id}/`,
  getReportedQuestion: (status: string) => `cms/content_feedback/reported_question/list/?review_status=${status}`,
  getStaffList: () => `cms/content_feedback/staff-list/`,
  getReportDetails: (id: string) => `cms/content_feedback/user_report/list/?reported_content_id=${id}`,
  assignStaff : (id: string) => `cms/content_feedback/report/update/${id}/`,
  markAsCorrected: (id: string, notify_users: boolean) => `cms/content_feedback/report/update/${id}/?notify_users=${notify_users}`,
  markAsNoChange : (id: string, notify_users: boolean) => `cms/content_feedback/report/update/${id}/?notify_users=${notify_users}`,
  qualityCheck: (id: string) => `cms/content/mock_test/question/quality_check/${id}/`,
  qualityCheckIndividualType:(id: string, type: string) => `cms/content/mock_test/question/quality_check/${id}/?check_type=${type}`,
  passFailQC: (id: string) => `cms/content/mock_test/question/quality_check/status_change/${id}/`,
  passFailQCLog: (id: string, type: string) => `cms/content/mock_test/question/quality_check/log/list/?question_id=${id}&quality_check_type=${type}`,
  orderAndPayments: () => `cms/payment/order/list/`,
  userPermissions_create:'cms/staff/create/',
  userPermissions_update:(id:string)=>`cms/staff/update/${id}/`,
  userPermissions_retrieve:'cms/staff/list/',
  [allModules[0]]: {
    list: (status?: string) => `cms/exam/list/?status=${status}`,
    create: "cms/exam/create/",
    edit: (id: string) => `cms/exam/update/${id}/`,
    item: "",
  },
  [allModules[1]]: {
    // goal/list/?statu
    list: (status: string, id: string) => `cms/goal/list/?exam=${id}`,
    create: "cms/goal/create/",
    edit: (id: string) => `cms/goal/update/${id}/`,
    item: "",
  },
  [allModules[2]]: {
    list: (status: string, id: string) => `cms/syllabus/list/?goal=${id}`,
    create: "cms/syllabus/create/",
    edit: (id: string) => `cms/syllabus/update/${id}/`,
    item: "",
    delete: (id: string) => `cms/syllabus/delete/${id}/`,
  },
  [allModules[3]]: {
    list: (status: string, id: string, parent: string) =>
      `cms/syllabus/list/?goal=${parent}&parent=${id}`,
    create: "cms/syllabus/create/",
    edit: (id: string) => `cms/syllabus/update/${id}/`,
    item: "",
    delete: (id: string) => `cms/syllabus/delete/${id}/`,
  },
  [allModules[4]]: {
    list: (status: string, id: string, parent: string) =>
      `cms/syllabus/list/?goal=${parent}&parent=${id}`,
    create: "cms/syllabus/create/",
    edit: (id: string) => `cms/syllabus/update/${id}/`,
    item: "",
    delete: (id: string) => `cms/syllabus/delete/${id}/`,
  },
  [allModules[5]]: {
    list: (status: string, id: string, parent: string) =>
      `cms/syllabus/list/?goal=${parent}&parent=${id}`,
    create: "cms/syllabus/create/",
    edit: (id: string) => `cms/syllabus/update/${id}/`,
    item: "",
    delete: (id: string) => `cms/syllabus/delete/${id}/`,
  },
  [allModules[6]]: {
    list: (status: string, id: string, cluster_type: string) =>
      // ${status!==null?`&status=${status}`:``}
      `cms/content/syllabus/cluster/list/?syllabus_tree_node_id=${id}${
        cluster_type ? `&cluster_type=${cluster_type}` : ""
      }`,

    create: `cms/content/cluster/add/`,
    create2: "cms/content/cluster/question/add/",
    edit: (id: string) =>  `cms/content/cluster/add/`,
    item: "",
    delete: (id: string) => `cms/syllabus/delete/${id}/`,
    status_update:(id:string)=>`cms/content/cluster/status_change/${id}`
  },
  [allModules[7]]: {
    list: (
      status: string,
      id: string,
      cluster_type: string,
      pagination: { page: number; size: number }
    ) =>
      `cms/content/question/list/?cluster_id=${id}${
        status !== null ? `&status=${status}` : ``
      }${pagination?.page ? `&page=${pagination.page}` : ""}${pagination?.size ? `&page_size=${pagination.size}` : ""}`,
    create: `cms/content/question/create/`,
    create2: "api/cms/content/question/add/",
    edit: (id: string) => `cms/content/question/update/`,
    item: (id: string) => `cms/content/question/${id}/`,
    delete: (id: string) => `cms/content/cluster/delete/${id}/`,

    status_update:(id:string)=>`cms/content/question/update/`
  },
  // subtopic:(topic_id: any)=>`topic/${topic_id}/subtopic/`,

  // apis and modules for MockTest Module

  [allModulesInMockTest[0]]: {
    list: (status?: string) => `cms/exam/list/?status=${status}`,
    create: "cms/exam/create/",
    edit: (id: string) => `cms/exam/update/${id}/`,
    item: "",
  },
  [allModulesInMockTest[1]]: {
    // goal/list/?statu
    list: (status: string, id: string) => `cms/goal/list/?exam=${id}`,
    create: "cms/goal/create/",
    edit: (id: string) => `cms/goal/update/${id}/`,
    item: "",
  },
  [allModulesInMockTest[2]]: {
    // goal/list/?statu
    list: (status: string, id: string) => `cms/content/mock_test_series/list/?goal_id=${id}`,
      // `cms/goal/list/?exam=${`83fd5e00-6576-4f83-9c36-54f4619df90c`}`,
    create: "cms/content/mock_test_series/create/",
    // "cms/goal/create/",
    edit: (id: string) => `cms/content/mock_test_series/update/${id}`,
    item: "",
  },
  [allModulesInMockTest[3]]: {
    list: (status: string, id: string) => `cms/content/mock_test/list/?mock_test_series_id=${id}`,
    create: "cms/content/mock_test/create/",
    edit: (id: string) => `cms/content/mock_test/update/${id}`,
    item: "",
    delete: (id: string) => `cms/content/mock_test/update/${id}`,
  },
  [allModulesInMockTest[4]]: { list: (status: string, id: string, parent: string) =>
    `cms/content/mock_test/section/list/?mock_test_id=${id}`,
    create: "cms/syllabus/create/",
    edit: (id: string) => `cms/syllabus/update/${id}/`,
    item: "",
    delete: (id: string) => `cms/syllabus/delete/${id}/`,
  },
  [allModulesInMockTest[5]]: {
    list: (
      status: string,
      id: string,
      cluster_type: string,
      pagination: { page: number; size: number }
    ) =>
      `cms/content/mock_test/questions/list/?mock_test_section_id=${id}&status=${status}${pagination?.page ? `&page=${pagination.page}` : ""}${pagination?.size ? `&page_size=${pagination.size}` : ""}`,
      // `cms/content/question/list/?cluster_id=fa08826f-e549-4558-9223-cdaa241c486e&status=published&page=1&page_size=10`,
      
      // cluster_id=${id}${
      //   status !== null ? `&status=${status}` : ``
      // }${pagination?.page ? `&page=${pagination.page}` : ""}${pagination?.size ? `&page_size=${pagination.size}` : ""}`,
    create :  (
      id: string,
      status: string,
    ) =>  `cms/content/mock_test/question/create/?mock_test_section_id=${id}&status=${status}`,
    create2: "api/cms/content/question/add/",
    edit: (id: string) => `cms/content/question/update/`,
    item: (id: string) => `cms/content/mock_test/question/${id}/`,
    delete: (id: string) => `cms/content/cluster/delete/${id}/`,

    status_update:(id:string)=>`cms/content/question/update/`
  },
};
