import React, { useState, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Checkbox,
  Typography,
  Breadcrumbs,
  Link,
  Paper,
  Menu,
  MenuItem,
  Divider,
  FormControlLabel,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createInternalUser,
  updateInternalUser,
  fetchInternalUsers,
  InternalUser,
} from "../../store/slices/InternalUsers/InternalUserSlice";
import { AppDispatch, RootState } from "../../store/store";

interface FormData {
  id: number | null;
  name: string;
  email: string;
  phone: string;
  is_active: boolean;
}

interface PermissionData {
  userId: number | null;
  module: string;
  actions: {
    status: boolean;
    edit: boolean;
    delete: boolean;
  };
}

const InternalUsers: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { users, status, error } = useSelector(
    (state: RootState) => state.internalUserSlice
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showPermissionsForm, setShowPermissionsForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    id: null,
    name: "",
    email: "",
    phone: "",
    is_active: true,
  });
  const [permissionData, setPermissionData] = useState<PermissionData>({
    userId: null,
    module: "",
    actions: {
      status: false,
      edit: false,
      delete: false,
    },
  });
  const [anchorEls, setAnchorEls] = useState<{ [key: string | number]: HTMLElement | null }>({});
  const [editingUserId, setEditingUserId] = useState<number | null>(null);
  const [formError, setFormError] = useState<string>("");

  React.useEffect(() => {
    dispatch(fetchInternalUsers());
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, userId: string | number) => {
    setAnchorEls((prev) => ({
      ...prev,
      [userId]: event.currentTarget,
    }));
  };

  const handleAddNewClick = () => {
    setEditingUserId(null);
    setFormData({
      id: null,
      name: "",
      email: "",
      phone: "",
      is_active: true,
    });
    setShowForm(true);
  };

  const handleClose = (userId: string | number) => {
    setAnchorEls((prev) => {
      const newAnchorEls = { ...prev };
      delete newAnchorEls[userId];
      return newAnchorEls;
    });
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, is_active: e.target.checked });
  };

  const handlePermissionChange = (e: SelectChangeEvent<string>) => {
    setPermissionData({
      ...permissionData,
      module: e.target.value,
    });
  };

  const handleActionCheckboxChange = (e: ChangeEvent<HTMLInputElement>, action: string) => {
    setPermissionData({
      ...permissionData,
      actions: {
        ...permissionData.actions,
        [action]: e.target.checked,
      },
    });
  };

  const handleFormSubmit = async () => {
    setFormError("");
    setIsLoading(true);

    // Validation
    if (!formData.name || !formData.email) {
      setFormError("Please fill out the required fields.");
      setIsLoading(false);
      return;
    }

    if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
      setFormError("Phone number must be exactly 10 digits.");
      setIsLoading(false);
      return;
    }

    const payload = {
      id: editingUserId?.toString() || "",
      userData: {
        email: formData.email,
        user_data: { full_name: formData.name },
        phone: formData.phone,
        is_active: formData.is_active,
      },
    };

    try {
      if (editingUserId !== null) {
        await dispatch(updateInternalUser(payload)).unwrap();
      } else {
        await dispatch(createInternalUser(payload.userData)).unwrap();
      }
      resetForm();
      setShowForm(false);
      dispatch(fetchInternalUsers());
    } catch (err) {
      console.error("Error during submission:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPermissions = (user: InternalUser) => {
    setPermissionData({
      userId: user.id ? Number(user.id) : null,  // Fallback to null if undefined
      module: "",
      actions: {
        status: false,
        edit: false,
        delete: false,
      },
    });
    
    setShowPermissionsForm(true);
    handleClose(user.id ?? ""); // If undefined, fallback to an empty string or valid ID
  };

  const handlePermissionFormSubmit = () => {
    console.log("Permissions submitted:", permissionData);
    setShowPermissionsForm(false);
  };

  const handleEditClick = (user: InternalUser) => {
    setEditingUserId(user.id ? Number(user.id) : null);
    setFormData({
      id: user.id ? Number(user.id) : null,  // Ensure the id is a valid number or null
      name: user.user_data.full_name,
      email: user.email,
      phone: user.phone,
      is_active: user.is_active,  // Add is_active from user
    });
    
    
    setShowForm(true);
  };

  const handleCancel = () => {
    resetForm();
    setShowForm(false);
    setShowPermissionsForm(false);
  };

  const resetForm = () => {
    setFormData({ id: null, name: "", email: "", phone: "", is_active: true });
    setShowForm(false);
    setEditingUserId(null);
    setFormError("");
  };

  const isSaveDisabled = !formData.name || !formData.email;

  return (
    <Paper style={{ width: "99%", height: "90vh", overflow: "hidden" }}>
      <div style={{ padding: "16px" }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "16px" }}>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate("/usersPermissions")}
            style={{ cursor: "pointer" }}
          >
            Users and Permissions
          </Link>
          <Typography color="text.primary">Internal Users</Typography>
        </Breadcrumbs>

        {showForm ? (
          <div>
            <Typography variant="h5" gutterBottom>
              {editingUserId ? "Edit User" : "Add New User"}
            </Typography>
            <Divider />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "200px",
                marginTop: "20px",
              }}
            >
              <TextField
                label="Full Name"
                variant="outlined"
                style={{ flex: "0 0 28%" }}
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                style={{ flex: "0 0 28%" }}
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                required
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                style={{ flex: "0 0 28%" }}
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
              />
              <div
                style={{
                  flex: "0 0 28%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={formData.is_active}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "Active Checkbox" }}
                />
                <Typography>Active</Typography>
              </div>
            </div>

            {formError && (
              <Typography color="error" variant="body2" style={{ marginTop: "8px" }}>
                {formError}
              </Typography>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "220px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancel}
                style={{ flex: "0 0 10%", textTransform: "none" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFormSubmit}
                style={{ flex: "0 0 10%", textTransform: "none" }}
                disabled={isSaveDisabled || isLoading}
              >
                Save User
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <TextField
              variant="outlined"
              placeholder="Search Internal Users"
              style={{ width: "30%", marginBottom: "16px" }}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "16px", float: "right" }}
              startIcon={<AddIcon />}
              onClick={handleAddNewClick}
            >
              Add New
            </Button>
            <TableContainer
              component={Paper}
              style={{ maxHeight: "550px", overflowY: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>is_active</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === "loading" ? (
                    <TableRow>
                      <TableCell colSpan={5}>Loading...</TableCell>
                    </TableRow>
                  ) : error ? (
                    <TableRow>
                      <TableCell colSpan={5}>Error loading data</TableCell>
                    </TableRow>
                  ) : (
                    users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.user_data.full_name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>{user.is_active ? "Active" : "Inactive"}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-controls="user-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, user.id ?? "")}

                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
  anchorEl={anchorEls[user.id ?? ""]}  
  open={Boolean(anchorEls[user.id ?? ""])}  
  onClose={() => handleClose(user.id ?? "")}  
>

                            <MenuItem onClick={() => handleEditClick(user)}>Edit</MenuItem>
                            <MenuItem onClick={() => handleAddPermissions(user)}>
                              Add Permissions
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>

      {showPermissionsForm && (
        <div>
          <Typography variant="h5" gutterBottom>
            Assign Permissions for {permissionData.userId}
          </Typography>
          <FormControl variant="outlined" style={{ width: "100%", marginBottom: "16px" }}>
            <InputLabel>Module</InputLabel>
            <Select
              value={permissionData.module}
              onChange={handlePermissionChange}
              label="Module"
              fullWidth
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissionData.actions.status}
                onChange={(e) => handleActionCheckboxChange(e, "status")}
              />
            }
            label="Status"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissionData.actions.edit}
                onChange={(e) => handleActionCheckboxChange(e, "edit")}
              />
            }
            label="Edit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissionData.actions.delete}
                onChange={(e) => handleActionCheckboxChange(e, "delete")}
              />
            }
            label="Delete"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handlePermissionFormSubmit}
          >
            Save Permissions
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default InternalUsers;
